import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styled, { useTheme } from 'styles/styled';
import { ROUTES } from 'constants/common';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import { OuterWrapper } from 'components/Layout/styled';
import FacilitiesContent from 'components/FacilitiesContent';
import bg from 'images/background/home-bg.jpg';
import facilitiesBg from 'images/background/facilities.png';

const Wrapper = styled.div`
  position: relative;

  ${OuterWrapper} {
    &::before {
      background-image: url(${bg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      height: 100vh;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
    }

    &::after {
      background-image: url(${facilitiesBg});
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
      height: 100vh;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
    }
  }
`;

const Facilities = () => {
  const theme = useTheme();
  const { wpPage } = useStaticQuery(graphql`
    query {
      wpPage(slug: { in: "facilities" }) {
        ...MetaData
      }
    }
  `);

  wpPage.seo.opengraphUrl = ROUTES.facilities;

  return (
    <Wrapper>
      <Layout backgroundColor={theme.palette.dark}>
        <SEO metadata={wpPage || {}} />
        <FacilitiesContent />
      </Layout>
    </Wrapper>
  );
};

export default Facilities;
