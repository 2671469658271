import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { FacilitiesTypes } from 'types';
import { FluidImage } from 'types';

import AvailabilitySlider from 'components/FacilitiesContent/AvailabilitySlider';
import Circles from 'components/FacilitiesContent/Circles';
import Miners from 'components/FacilitiesContent/Miners';

import {
  Wrapper,
  Title,
  DataCenters,
  Availability,
  SliderWrapper,
} from './styled';

interface Gallery {
  nodes: FluidImage[];
}
type PageData = FacilitiesTypes.PageDataInterface;

const CIRCLES = [
  { label: 'Data Centers', value: 5 },
  { label: 'Operational Capacity', value: '160 MW' },
  { label: 'Planned Expansion', value: '220 MW' },
  { label: 'Global Mining Awards', value: 2 },
];

const FacilitiesContent = () => {
  const {
    page: {
      facilities: {
        availabilityDescription,
        availabilityTitle,
        contactCopy,
        description,
        title,
      },
    },
    ourMiners,
  } = useStaticQuery<{
    page: PageData;
    gallery: Gallery;
    ourMiners: FacilitiesTypes.OurMiners;
  }>(graphql`
    query FacilitiesContent {
      page: wpPage(slug: { eq: "facilities" }) {
        facilities {
          availabilityDescription
          availabilityTitle
          contactCopy
          description
          title
        }
      }

      ourMiners: allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { slug: { eq: "our-miners" } } } }
        }
      ) {
        nodes {
          our_miners {
            name
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  id
                }
              }
            }
          }
        }
      }
    }
  `);

  const sliderData = ourMiners.nodes.map(({ our_miners }) => our_miners);

  return (
    <Wrapper>
      <Circles data={CIRCLES} copy={contactCopy} />
      <DataCenters>
        <Title as="h1">{title}</Title>
        <p>{description}</p>
      </DataCenters>
      <Availability>
        <Title>{availabilityTitle}</Title>
        <p>{availabilityDescription}</p>
      </Availability>
      <SliderWrapper>
        <AvailabilitySlider />
      </SliderWrapper>

      <Miners data={sliderData} />
    </Wrapper>
  );
};

export default FacilitiesContent;
