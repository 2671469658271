import React from 'react';
import Img from 'gatsby-image';

import { FluidImage } from 'types';

import {
  MinersList,
  MinerWrapper,
  MinerImgWrapper,
  MinersWrapper,
  Title,
} from './styled';

interface Props {
  data: { image: FluidImage; name: string }[];
}

const Miners = ({ data }: Props) => {
  return (
    <MinersWrapper>
      <Title>Our miners</Title>
      <MinersList>
        {data.map(({ image, name }) => {
          return (
            <MinerWrapper key={name}>
              <MinerImgWrapper>
                <Img
                  fluid={{ ...image.localFile.childImageSharp.fluid }}
                  alt={name}
                />
              </MinerImgWrapper>
              <p>{name}</p>
            </MinerWrapper>
          );
        })}
      </MinersList>
    </MinersWrapper>
  );
};

export default Miners;
