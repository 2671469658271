import styled from 'styles/styled';

import { Button } from 'components/UI/Button';

export const Wrapper = styled.div`
  padding: 0 1.125rem;
  margin: 0 0 105px;
  position: relative;
  text-align: center;

  @media ${({ theme }) => theme.devices.large} {
    padding: 0;
    margin-bottom: 150px;
    text-align: left;
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;

  span,
  strong {
    display: block;
  }

  @media ${({ theme }) => theme.devices.medium} {
    font-size: 26px;
  }
`;

export const SliderWrapper = styled.section`
  position: relative;
  left: 50%;
  max-width: 100%;
  transform: translateX(-50%);
  width: 100vw;
`;

export const CirclesContainer = styled.section`
  margin: 30px 0;

  @media ${({ theme }) => theme.devices.medium} {
    margin: 100px 0;
  }

  @media ${({ theme }) => theme.devices.large} {
    margin: 200px 0 100px;
  }
`;

export const CirclesWrapper = styled.div`
  display: flex;
  height: 180px;
  justify-content: center;
  position: relative;

  @media ${({ theme }) => theme.devices.medium} {
    > div {
      & + div {
        margin-left: 20px;
      }
    }
  }
`;

export const Circle = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 180px;
  left: 50%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  text-align: center;
  padding: 25px;
  position: absolute;
  transform: translateX(-50%);
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  width: 180px;

  @media ${({ theme }) => theme.devices.medium} {
    opacity: 1;
    position: static;
    transform: none;
  }
`;

export const CircleValue = styled.p`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;
`;

export const CirclesPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  > div + div {
    margin-left: 10px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    display: none;
  }
`;

export const CirclesPaginationDot = styled.div<{ active: boolean }>`
  background-color: ${({ active, theme }) =>
    active ? theme.palette.light : 'transparent'};
  border: 2px solid ${({ theme }) => theme.palette.light};
  border-radius: 50%;
  cursor: pointer;
  height: 8px;
  width: 8px;
`;

export const CirclesCopyWrapper = styled.div`
  text-align: center;
`;

export const CirclesCopy = styled.p`
  margin-top: 30px;
`;

export const StyledLink = styled(Button)`
  display: inline-block;
  margin: 20px auto 0;
`;

export const DataCenters = styled.section`
  margin: 70px 0;

  @media ${({ theme }) => theme.devices.large} {
    margin: 100px auto;
    max-width: ${({ theme }) => theme.dimensions.contentMaxWidthSmall}px;
  }
`;

export const Availability = styled.section`
  margin-bottom: 30px;

  p {
    text-align: center;
  }
`;

export const Image = styled.img`
  border-radius: 4px;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthSmall}px;
  max-height: 486px;
  object-fit: cover;
`;

export const SwiperImageWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

export const MinersWrapper = styled.section`
  max-width: 400px;
  margin: 0 auto;
  margin-top: 70px;

  ${Title} {
    margin-bottom: 20px;

    @media ${({ theme }) => theme.devices.medium} {
      margin-bottom: 30px;
    }
  }

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: 100px;
    max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
  }
`;

export const MinersList = styled.div`
  @media ${({ theme }) => theme.devices.medium} {
    display: flex;
    justify-content: center;
  }
`;

export const MinerWrapper = styled.div`
  & + & {
    margin-top: 30px;
  }

  p {
    font-weight: 700;
    margin-top: 10px;
    text-transform: uppercase;
  }

  @media ${({ theme }) => theme.devices.medium} {
    flex-basis: 33%;
    text-align: center;

    & + & {
      margin-left: 30px;
      margin-top: 0;
    }
  }
`;

export const MinerImgWrapper = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    z-index: 1;
  }

  &::before {
    background-image: radial-gradient(
      closest-side,
      rgba(0, 90, 160, 0.6),
      rgba(0, 90, 160, 0)
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  &::after {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    bottom: 0;
    content: '';
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 2;
  }
`;
