import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';

import { SwiperImageWrapper } from './styled';

const data = [
  <StaticImage
    alt=""
    key="1"
    src="../../images/facilities/minebest_cryptocurrency_mining_equipment.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="2"
    src="../../images/facilities/minebest_bitcoin_mining_machines.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="3"
    src="../../images/facilities/minebest_asic_mining_rig.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="4"
    src="../../images/facilities/minebest_bitcoin_mining_facility.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="5"
    src="../../images/facilities/crypto_mining_rig_asic_miner.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="6"
    src="../../images/facilities/minebest_digital_miner.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="7"
    src="../../images/facilities/minebest_mining_company_kazakhstan.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="8"
    src="../../images/facilities/minebest_blockchain_mining_equipment.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="9"
    src="../../images/facilities/cryptocurrency_mining_facility_minebest.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="10"
    src="../../images/facilities/bitcoin_mining_hardware_minebest.jpg"
    quality={100}
    placeholder="blurred"
  />,
  <StaticImage
    alt=""
    key="11"
    src="../../images/facilities/minebest_crypto_mining_maintenance.jpg"
    quality={100}
    placeholder="blurred"
  />,
];

const Slider = () => {
  const ref = useRef<SwiperCore | null>(null);

  useEffect(() => {
    setTimeout(() => {
      ref.current?.slidePrev(0, false);
    }, 0);
  }, []);

  return (
    <>
      <Swiper
        spaceBetween={10}
        centeredSlides
        slidesPerView="auto"
        updateOnWindowResize
        preventInteractionOnTransition
        observer
        onSwiper={(s) => {
          ref.current = s;
        }}
        breakpoints={{
          768: {
            spaceBetween: 30,
          },
        }}
        loop
        loopedSlides={data.length}
      >
        {data.map((el, i) => (
          <SwiperSlide key={i} style={{ width: 'auto' }}>
            <SwiperImageWrapper>{el}</SwiperImageWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Slider;
