import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { ROUTES } from 'constants/common';

import {
  Circle,
  CirclesCopy,
  CirclesCopyWrapper,
  CirclesContainer,
  CirclesPagination,
  CirclesPaginationDot,
  CircleValue,
  CirclesWrapper,
  StyledLink,
} from './styled';

interface Props {
  copy: string;
  data: { label: string; value: string | number }[];
}

const Circles = ({ copy, data }: Props) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActive((a) => {
        const next = a + 1;
        return next === data.length ? 0 : next;
      });
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [active, data]);

  return (
    <CirclesContainer></CirclesContainer>
      // <CirclesWrapper>
      //   {data.map(({ label, value }, i) => (
      //     <Circle key={label} active={i === active}>
      //       <CircleValue>{value}</CircleValue>
      //       <p>{label}</p>
      //     </Circle>
      //   ))}
      // </CirclesWrapper>

      // <CirclesPagination>
      //   {data.map(({ label }, i) => (
      //     <CirclesPaginationDot
      //       key={label}
      //       active={i === active}
      //       onClick={() => setActive(i)}
      //     />

      //   ))}
      // </CirclesPagination>
	  //
      // <CirclesCopyWrapper>
      //   <CirclesCopy>{copy}</CirclesCopy>
      //   <StyledLink as={Link} to={ROUTES.contact}>
      //     Contact us
      //   </StyledLink>
      // </CirclesCopyWrapper>
  );
};

export default Circles;
